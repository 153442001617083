<template>
  <auth-layout
    :title="$t('Add your account name')"
    :loading="loading"
  >
    <template v-slot:body>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            v-model="form.first_name"
            :error-messages="firstError('form.first_name')"
            :label="$t('First Name')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            v-model="form.last_name"
            :error-messages="firstError('form.last_name')"
            :label="$t('Last Name')"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>

    <template v-slot:footer>
      <v-btn
        type="submit"
        @click="submit"
        :loading="loading"
        color="primary"
        class="px-4"
        dark
        block
      >{{$t('Save')}}</v-btn>
    </template>
  </auth-layout>
</template>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import axios from 'axios';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  mixins: [validationMixin, FormValidationMixin],
  validations: {
    form: {
      first_name: {
        required,
        txtMinLen: minLength(2),
      },
      last_name: {
        required,
        txtMinLen: minLength(2),
      },
    },
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios
        .post(`apple-login-add-name/${this.$route.params.id}`, {
          ...this.form,
        })
        .then(() => {
          this.loading = false;
          window.location = '/user-settings/account';
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch('setErrors', error.response.data.errors);
        });
    },
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
