import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import storage from '../lib/storage';
import i18n from '../i18n';
// import router from '../router';

Vue.use(Vuex);

axios.defaults.baseURL = `${process.env.VUE_APP_APP_URL}/api`;

export default new Vuex.Store({
  state: {
    user: null,
    messages: [],
    errorsBackend: {},
    loading: false,
    backgroundColor: 'transparent',
    calculator: {},
    editReservationId: null,
    deleteReservation: null,
    reservationPreview: null,
    inquiryPreview: null,

    showReservationModal: false,
  },

  mutations: {
    setAccessToken(stata, accessToken) {
      storage.setItem('access_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    },
    updateUser(state, user) {
      state.user = user;
    },
    setPriceCalculator(state, calculator) {
      state.calculator = calculator;
    },
    editReservationId(state, id) {
      state.editReservationId = id;
    },
    deleteReservation(state, id) {
      state.deleteReservation = id;
    },
    clearDeleteReservation(state) {
      state.deleteReservation = undefined;
    },

    setReservationPreview(state, reservation) {
      state.reservationPreview = reservation;
    },
    setInquiryPreview(state, inquiry) {
      state.inquiryPreview = inquiry;
    },
    clearReservationPreview(state) {
      state.reservationPreview = undefined;
    },
    clearInquiryPreview(state) {
      state.inquiryPreview = undefined;
    },

    toggleReservationModal(state) {
      state.showReservationModal = !state.showReservationModal;
    },

    // updateSettings(state, settings) {
    //   const userData = storage.getItem('user');
    //   userData.user.settings = settings;
    //   storage.setItem('user', userData);
    //   state.user.user.settings = settings;
    // },

    clearUserData() {
      storage.removeItem('access_token');
      storage.removeItem('user'); // ToDo remove this -> it wont be in storage
      /* eslint no-restricted-globals: ["error", "event"] */
      location.reload();
    },

    message(state, message) {
      const rand = Math.floor(Math.floor(Math.random() * 10000));
      state.messages.push({
        id: rand,
        model: rand,
        text: message,
      });
    },

    clearErrors(state) {
      state.errorsBackend = {};
    },
    setErrors(state, errors) {
      state.errorsBackend = errors;
    },

    loading(state, loading) {
      state.loading = loading;
    },

    setSilverBackgroundColor(state) {
      state.backgroundColor = '#f2f2f2';
    },
    removeBackgroundColor(state) {
      state.backgroundColor = 'transparent';
    },

  },

  actions: {
    loadProfile({ commit, state }) {
      if (state.user) {
        return state.user;
      }

      return axios.get('me')
        .then((res) => {
          commit('updateUser', res.data);
          // set locale
          if (state.user && state.user.settings && state.user.settings.lang) {
            i18n.locale = state.user.settings.lang;
            axios.defaults.headers.common['App-Language'] = i18n.locale;
          }
          return state.user;
        });
    },
    updateUser({ commit }, user) {
      commit('updateUser', user);
    },
    loginForApp({ dispatch }, authData) {
      return axios
        .post(`/sociallogin/${authData.provider}`, authData)
        .then(({ data: loginResponse }) => {
          const authParams = { ...loginResponse };
          dispatch('loginUser', authParams);
        })
        .catch((err) => {
          console.log({ err });
        });
    },
    tryLogin({ dispatch }, credentials) {
      return axios
        .post('/login', credentials)
        .then(({ data: loginResponse }) => {
          dispatch('loginUser', loginResponse);
        });
    },
    loginUser({ commit, dispatch }, loginResponse) {
      commit('clearErrors');
      commit('setAccessToken', loginResponse.token);
      if (loginResponse.redirect_url) {
        window.location.href = loginResponse.redirect_url;
        return;
      }
      dispatch('loadProfile');

      if (loginResponse.delay) {
        setTimeout(() => {
          window.location = '/user-settings/account';
        }, loginResponse.delay);
      } else {
        window.location = '/user-settings/account';
      }
    },
    logout({ commit }) {
      commit('clearUserData');
      // Redirect to website and logout
      window.location.href = `${process.env.VUE_APP_APP_URL}/spa-auth-out`;
    },

    message({ commit }, message) {
      commit('message', message);
    },
    setErrors({ commit }, errors) {
      commit('setErrors', errors);
    },
    clearErrors({ commit }) {
      commit('clearErrors');
    },

    updateSettings(store, settings) {
      // commit('updateSettings', settings);
      return axios
        .post('user-settings/update-settings', settings);
    },

    loading({ commit }, loading) {
      commit('loading', loading);
    },

    setSilverBackgroundColor({ commit }) {
      commit('setSilverBackgroundColor');
    },
    removeBackgroundColor({ commit }) {
      commit('removeBackgroundColor');
    },

    getAdCategories(store, withAppend = '') {
      return axios
        .get(`panel/ads/categories?with=${withAppend}`)
        .then(({ data }) => data);
    },
    getAdCategoryByConst(store, categoryConst) {
      return axios
        .get(`panel/ads/categories/const/${categoryConst}`)
        .then(({ data }) => data);
    },

    getAds() {
      return axios
        .get('panel/ads')
        .then(({ data }) => data);
    },
    getAdsForCategoryConst(store, categoryConst) {
      return axios
        .get(`panel/ads/category-const/${categoryConst}`)
        .then(({ data }) => data);
    },
    getAd(store, adId) {
      return axios
        .get(`panel/ads/${adId}`)
        .then(({ data }) => data);
    },
    getCategoryTags(store, categoryId) {
      return axios
        .get(`panel/ads/tags/category/${categoryId}`)
        .then(({ data }) => data);
    },
    getSubcategoriesForCategory(store, categoryId) {
      return axios
        .get(`panel/ads/subcategories/category/${categoryId}`)
        .then(({ data }) => data);
    },
    getCountries() {
      return axios
        .get('countries')
        .then(({ data }) => data);
    },
    specialPricing(store, id) {
      return axios
        .get(`calendar/special-price/${id}`)
        .then(({ data }) => data);
    },
    minStayBlocked(store, params) {
      return axios
        .get(`calendar/min-stay-blocked/${params.id}`, {
          params: { start: params.start },
        })
        .then(({ data }) => data);
    },
    calendar(store, params) {
      return axios
        .get(`calendar/${params.id}`, {
          params: {
            start: params.start,
            excludeReservationID: params.excludeReservationID ?? null,
          },
        })
        .then(({ data }) => data);
    },
    calendarInitData(store, id) {
      return axios
        .get(`calendar/init-data/${id}`)
        .then(({ data }) => data);
    },
    calendarRules(store, id) {
      return axios
        .get(`calendar/rules/${id}`)
        .then(({ data }) => data);
    },
    calculatePrice({ commit }, params) {
      return axios
        .get(`calendar/${params.id}/pricing`, {
          params,
        })
        // .then(({ data }) => data);
        .then(({ data }) => {
          commit('setPriceCalculator', data);
          return data;
        });
    },
    editReservationId({ commit }, params) {
      commit('editReservationId', params);
    },
    deleteReservation({ commit }, params) {
      commit('deleteReservation', params);
    },
    clearDeleteReservation({ commit }) {
      commit('clearDeleteReservation');
    },

    loadReservation(store, id) {
      return axios
        .get(`calendar/reservations/${id}`)
        .then(({ data }) => data);
    },
    setReservationPreview({ commit }, params) {
      return axios
        .get(`calendar/reservations/${params}`)
        .then(({ data }) => {
          commit('setReservationPreview', data);
          return data;
        });
    },
    setInquiryPreview({ commit }, params) {
      return axios
        .get(`calendar/inquries/${params}`)
        .then(({ data }) => {
          commit('setInquiryPreview', data);
          return data;
        });
    },
    clearReservationPreview({ commit }) {
      commit('clearReservationPreview');
    },
    clearInquiryPreview({ commit }) {
      commit('clearInquiryPreview');
    },
    refreshIcal(store, id) {
      return axios
        .post(`calendar/refresh-ical/${id}`)
        .then(({ data }) => data);
    },
  },

  getters: {
    isLogged: (state) => !!state.user,
    user: (state) => state.user,
    isAdmin: (state) => state.user && state.user.rolesAndPermissions && state.user.rolesAndPermissions.roles && state.user.rolesAndPermissions.roles.includes('Admin'),
    messages: (state) => state.messages,
    loading: (state) => state.loading,
    backgroundColor: (state) => state.backgroundColor,
    errorsBackend: (state) => state.errorsBackend,
    calculator: (state) => state.calculator,
    editReservationId: (state) => state.editReservationId,
    deleteReservation: (state) => state.deleteReservation,
    reservationPreview: (state) => state.reservationPreview,
    inquiryPreview: (state) => state.inquiryPreview,
    showReservationModal: (state) => state.showReservationModal,
    hasValidationErrors: (state) => state.errorsBackend && Object.keys(state.errorsBackend).length,
    settings: (state) => {
      const defaultSettings = {
        lang: 'sr',
      };
      return { ...defaultSettings, ...state.user.settings };
    },
  },
});
